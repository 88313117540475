/* Estilos generales en reportes */

.switch {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.25rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 0.125rem;
    bottom: 0.125rem;
    background-color: white;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #6366f1;
}

input:focus + .slider {
    box-shadow: 0 0 0.0625rem #6366f1;
}

input:checked + .slider:before {
    transform: translateX(1.25rem);
}

.slider.round {
    border-radius: 1.25rem;
}

.slider.round:before {
    border-radius: 50%;
}