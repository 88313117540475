.richtexteditor-override {
    &.e-richtexteditor {
        @apply border border-slate-200 rounded shadow-sm;
    }

    &.e-rte-tb-expand .e-rte-toolbar.e-extended-toolbar,
    &.e-rte-tb-expand .e-rte-toolbar .e-toolbar-extended {
        @apply border-b border-slate-200 bg-pearl-100;
        z-index: 10 !important;

        &:not(.e-toolbar-pop) {
            @apply rounded-t;
        }
    }

    &.e-richtexteditor .e-toolbar-wrapper {
        @apply rounded-t bg-pearl-100 overflow-hidden;

        .e-toolbar .e-toolbar-items {
            @apply bg-pearl-100;
        }

        .e-toolbar .e-toolbar-item .e-tbar-btn,
        .e-toolbar .e-hor-nav {
            @apply text-slate-600 hover:text-slate-700 bg-pearl-100 hover:bg-slate-200;
        }
    }

    &.e-richtexteditor .e-rte-content,
    .e-richtexteditor .e-source-content {
        @apply bg-pearl-100 rounded-b;
    }
}
