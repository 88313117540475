/* Textarea */

.form-textarea {
    min-height: 2.375rem;

    &:disabled {
        background-color: #f1f5f9 !important;
        cursor: not-allowed;
        @apply border-slate-200 #{!important};
    }
}
