/* Custom Theming for Angular Material*/
/* For more information: https://material.angular.io/guide/theming*/
@use "@angular/material" as mat;
/* Plus imports for other components in your app.*/

/* Include the common styles for Angular Material. We include this here so that you only*/
/* have to load a single css file for Angular Material in your app.*/
/* Be sure that you only ever include this mixin once!*/
@include mat.core();

/* Define the palettes for your theme using the Material Design palettes available in palette.scss*/
/* (imported above). For each palette, you can optionally specify a default, lighter, and darker*/
/* hue. Available color palettes: https://material.io/design/color/*/
$mi-despacho-app-primary: mat.define-palette(mat.$indigo-palette);
$mi-despacho-app-accent: mat.define-palette(
    mat.$pink-palette,
    A200,
    A100,
    A400
);

/* The warn palette is optional (defaults to red).*/
$mi-despacho-app-warn: mat.define-palette(mat.$red-palette);

/* Create the theme object. A theme consists of configurations for individual*/
/* theming systems such as "color" or "typography".*/
$mi-despacho-app-theme: mat.define-light-theme(
    (
        color: (
            primary: $mi-despacho-app-primary,
            accent: $mi-despacho-app-accent,
            warn: $mi-despacho-app-warn,
        ),
    )
);

/* Include theme styles for core and each component used in your app.*/
/* Alternatively, you can import and @include the theme mixins for each component*/
/* that you are using.*/
@include mat.all-component-themes($mi-despacho-app-theme);

.container {
    position: relative;
}

.resize {
    display: flex;
    position: absolute;

    &__left,
    &__right {
        top: 0;
        bottom: 0;
        width: 0.625rem;
        height: auto;
        justify-content: center;
        cursor: e-resize;
    }

    &__right {
        right: -0.3125rem;
    }

    &__left {
        left: -0.3125rem;
    }

    &__top,
    &__top--left,
    &__top--right,
    &__bottom--left,
    &__bottom--right,
    &__bottom {
        left: 0;
        right: 0;
        height: 0.625rem;
        width: auto;
        align-items: center;
        cursor: s-resize;
    }

    &__top--left,
    &__top--right,
    &__top {
        top: -0.3125rem;

        &--left,
        &--right {
            width: 0.625rem;
            height: 0.625rem;
        }

        &--left {
            left: -0.3125rem;
            right: auto;
            cursor: se-resize;
        }

        &--right {
            right: -0.3125rem;
            left: auto;
            cursor: nesw-resize;
        }
    }

    &__bottom--left,
    &__bottom--right,
    &__bottom {
        bottom: -0.3125rem;

        &--left,
        &--right {
            width: 0.625rem;
            height: 0.625rem;
        }

        &--left {
            left: -0.3125rem;
            right: auto;
            cursor: nesw-resize;
        }

        &--right {
            right: -0.3125rem;
            left: auto;
            cursor: se-resize;
        }
    }
}
