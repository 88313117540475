/**
 * NO INCLUIR DECLARACIONES DE ESTILOS DIRECTAMENTE EN ESTE ARCHIVO
 * DEBE SEGUIR ESTA PROCESO ANTES DE AGREGAR ESTILOS:
 * 1. Evaluar si el estilo es importado desde url externa, si lo es, agregarlo a la sección 1.
 * 2. Evaluar si el estilo es importado desde node_modules, si lo es, agregarlo a la sección 2.
 * 3. Evaluar si el estilo es vendor (hoja de estilo importada al proyecto), si lo es, mover el archivo a la carpeta additional-styles y agregarlo a la sección 3.
 * 4. Evaluar si el estilo es alguna correccion u override de algun vendor, si lo es, crear el archivo y agregarlo a la sección 4.
 * 5. Evaluar si el estilo es corresponde a un componente o modulo de la app, si lo es, crear el archivo y agregarlo a la sección 5.
 */

/**
 * Seccion 1: Estilos importados desde urls externas
 * - Agregar nuevos estilos al final
 */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

/**
 * Seccion 2: Estilos importados desde node_modules
 * - Agregar nuevos estilos al final
 */
@import "../../../node_modules/@angular/cdk/overlay-prebuilt.css";
@import "../../../node_modules/animate.css/animate.css";
@import "../../../node_modules/tippy.js/dist/tippy.css";
@import "../../../node_modules/shepherd.js/dist/css/shepherd.css";
@import "../../../node_modules/ngx-toastr/toastr.css";
@import "../../../node_modules/air-datepicker/air-datepicker.css";
@import "../../../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css";
@import "../../../node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css";
@import "../../../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../../../node_modules/viewerjs/dist/viewer.min.css";
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";

/**
 * Seccion 3: Estilos importados desde vendors locales
 * - Agregar nuevos estilos al final
 */
@import "./additional-styles/intro.css";
@import "./additional-styles/icons.css";
@import "./additional-styles/ejs-text-editor.scss";
@import "./additional-styles/shepherd-styles.scss";
@import "./additional-styles/angular-resize-element.scss";
@import "./additional-styles/syncfusion-schedule.scss";
@import "./additional-styles/syncfusion-wordprocessor.scss";
@import "./additional-styles/uisr-tailwind.scss";
@import "./additional-styles/uisr-app-base.scss";

/**
 * Seccion 4: Estilos basados en correcciones
 * - Agregar nuevos estilos al final
 */
/*@import url("./fixed-styles/toggle-switch.scss");*/
@import "./fixed-styles/ng-select.scss";
@import "./fixed-styles/text-area.scss";
@import "./fixed-styles/toatr.scss";
@import "./fixed-styles/tippy.js.scss";
@import "./fixed-styles/air-datepicker.scss";
@import "./fixed-styles/swal.scss";
/*@import url("fixed-styles/input-radio-check.scss");*/
@import "./fixed-styles/input-checkbox.scss";
@import "./fixed-styles/input-radio.scss";
@import "./fixed-styles/user-journey.scss";
@import "./fixed-styles/syncfusion-schedule.scss";
@import "./fixed-styles/intercom-app.scss";

/**
 * Seccion 5: Estilos generales
 * - Agregar nuevos estilos al final
 */
@import "./general-styles/general.scss";
@import "./general-styles/reports.scss";
@import "./general-styles/notes.scss";
@import "./general-styles/random.scss";
