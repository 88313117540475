.shepherd-button {
    background: #4f48ed;
    border-radius: 0;
    color: whitesmoke;
    display: flex;
    font-size: 0.8rem;
    justify-content: center;
    margin: 0.5rem;
    padding: 0;
    text-align: center;
}

.shepherd-button:hover {
    background: #3933eb;
    color: #ffffff;
}

.shepherd-button.shepherd-button-secondary {
    background: #cad5d5;
}

.shepherd-button.shepherd-button-secondary:hover {
    color: #cad5d5;
    background: #3933eb;
}

.shepherd-element {
    border: solid 0.0625rem #4b45ed;
    z-index: 99999999 !important;
}

.shepherd-modal-overlay-container {
    z-index: 99999997 !important;
}

@media (max-width: 410px) {
    .shepherd-element {
        max-width: 90% !important;
    }
}

.shepherd-header {
    background: transparent !important;
}

.shepherd-element,
.shepherd-header,
.shepherd-footer {
    border-radius: 0;
}

.shepherd-element .shepherd-arrow {
    border-width: 0;
    height: auto;
    width: auto;
}

.shepherd-arrow::before {
    display: none;
}

.shepherd-element[data-popper-placement^="top"] .shepherd-arrow,
.shepherd-element.shepherd-pinned-top .shepherd-arrow {
    bottom: -2.1875rem;
}

.shepherd-element[data-popper-placement^="top"] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-top .shepherd-arrow:after {
    transform: rotate(270deg);
}

.shepherd-element[data-popper-placement^="bottom"] .shepherd-arrow {
    top: -2.1875rem;
}

.shepherd-element[data-popper-placement^="bottom"] .shepherd-arrow:after {
    transform: rotate(90deg);
}

.shepherd-element[data-popper-placement^="left"] .shepherd-arrow,
.shepherd-element.shepherd-pinned-left .shepherd-arrow {
    right: -2.1875rem;
}

.shepherd-element[data-popper-placement^="left"] .shepherd-arrow:after,
.shepherd-element.shepherd-pinned-left .shepherd-arrow:after {
    transform: rotate(180deg);
}

.shepherd-element[data-popper-placement^="right"] .shepherd-arrow,
.shepherd-element.shepherd-pinned-right .shepherd-arrow {
    left: -2.1875rem;
}

.shepherd-footer {
    padding: 0;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
    margin-top: -0.4375rem;
}

.shepherd-has-title .shepherd-content .shepherd-header {
    padding-bottom: 0;
    padding-left: 1rem !important;
}

.shepherd-has-title .shepherd-content .shepherd-header .shepherd-title {
    font-size: 1rem;
    color: #4b45ed !important;
}

.shepherd-text {
    font-size: 1rem !important;
    padding: 1rem !important;
}

.shepherd-text a,
.shepherd-text a:visited,
.shepherd-text a:active {
    border-bottom: 0.0625rem dotted;
    border-bottom-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
}

.shepherd-text a:hover,
.shepherd-text a:visited:hover,
.shepherd-text a:active:hover {
    border-bottom-style: solid;
}
