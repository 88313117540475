/* Typography */
.h1 {
    @apply text-4xl font-extrabold tracking-tighter;
}

.h2 {
    @apply text-3xl font-extrabold tracking-tighter;
}

.h3 {
    @apply text-3xl font-extrabold;
}

.h4 {
    @apply text-2xl font-extrabold tracking-tight;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }

    .h2 {
        @apply text-4xl;
    }
}

/* Forms */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none !important;
}

.form-input:disabled {
    @apply border-slate-200 bg-slate-100 text-slate-400 cursor-not-allowed;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
    @apply text-sm text-gray-800 bg-pearl-100 border;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
    @apply rounded !important;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
    @apply leading-5 py-2 px-3 border-slate-200 hover:border-slate-300 focus-visible:border-primary-75 shadow-sm !important;
}

.form-input,
.form-textarea {
    @apply placeholder-slate-400;
}

.form-select {
    @apply pr-10;
}

.form-checkbox,
.form-radio {
    @apply text-primary-100 border border-slate-300;
}

.form-value {
    @apply text-base text-gray-800 truncate;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none !important;
}

.no-scrollbar {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}

select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    print-color-adjust: exact;
}

[multiple] {
    background-image: initial;
    background-position: initial;
    background-repeat: unset;
    background-size: initial;
    padding-right: 0.75rem;
    print-color-adjust: unset;
}

[type="checkbox"],
[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #6f6af0;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 0.0625rem;
    --tw-shadow: 0 0 #0000;
}

[type="checkbox"] {
    border-radius: 0;
}

[type="radio"] {
    border-radius: 100%;
}

[type="checkbox"]:focus,
[type="radio"]:focus {
    @apply outline-offset-2;
    outline: 0.125rem solid transparent;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 0.125rem;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #6f6af0 !important;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
        var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
        calc(0.125rem + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
        var(--tw-shadow);
}

[type="checkbox"]:checked,
[type="radio"]:checked {
    border-color: transparent !important;
    background-color: #6366f1 !important;
    background-size: 100% 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus,
[type="radio"]:checked:hover,
[type="radio"]:checked:focus {
    border-color: transparent !important;
    background-color: #6366f1 !important;
}

[type="checkbox"]:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

[type="checkbox"]:indeterminate:hover,
[type="checkbox"]:indeterminate:focus {
    border-color: transparent;
    background-color: currentColor;
}

.form-switch {
    @apply flex items-center;
}

.form-switch input[type="checkbox"].sr-only {
    @apply relative select-none !block overflow-hidden cursor-pointer h-6 rounded-full bg-slate-400 w-11 bg-none;
}

.form-switch input[type="checkbox"].sr-only::after {
    @apply absolute block rounded-full bg-white shadow-sm w-5 h-5 top-2/4 -translate-y-2/4 left-[0.125rem] right-2/4;
    content: "";
    transition: all 0.15s ease-out;
}

.form-switch input[type="checkbox"].sr-only:checked {
    @apply bg-indigo-500;
}

.form-switch input[type="checkbox"].sr-only:checked::after {
    @apply left-[1.375rem];
}

.form-switch input[type="checkbox"].sr-only:disabled {
    @apply cursor-not-allowed opacity-90 border border-slate-200;
}
