/*@import "../../../../node_modules/@syncfusion/ej2-documenteditor/styles/document-editor-container/tailwind.scss";*/
@import "../../../../node_modules/@syncfusion/ej2-base/styles/definition/tailwind.scss";
@import "../../../../node_modules/@syncfusion/ej2-documenteditor/styles/document-editor/tailwind-definition.scss";
@import "../../../../node_modules/@syncfusion/ej2-documenteditor/styles/document-editor/icons/tailwind.scss";
@import "../../../../node_modules/@syncfusion/ej2-documenteditor/styles/document-editor/all.scss";
@import "../../../../node_modules/@syncfusion/ej2-documenteditor/styles/document-editor-container/tailwind-definition.scss";
@import "../../../../node_modules/@syncfusion/ej2-documenteditor/styles/document-editor-container/icons/tailwind.scss";
@import "../../../../node_modules/@syncfusion/ej2-documenteditor/styles/document-editor-container/all.scss";

.document-component-wrapper ejs-documenteditorcontainer {
    height: 100% !important;
    min-height: 100% !important;
    position: relative !important;
    z-index: 0 !important;
}
