/* Tippy */

.tippy-arrow {
    width: 1rem;
    height: 1rem;
    color: #fbfdfe;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip .tippy-arrow {
    border-bottom: 0.4375rem solid red;
    /* your color here */
}

.tippy-box {
    position: relative;
    background-color: #fbfdfe;
    color: #141926;
    border-radius: 0.25rem;
    font-size: 0.8rem;
    line-height: 1.4;
    white-space: normal;
    outline: 0;
    border: 0.0625rem solid #e2e8f0;
    transition-property: transform, visibility, opacity;
    --tw-shadow: 0 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.08),
        0 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.01);
    --tw-shadow-colored: 0 0.625rem 0.9375rem -0.1875rem var(--tw-shadow-color),
        0 0.25rem 0.375rem -0.125rem var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
