/* Input Checkbox */

.form-checkbox {
    @apply shadow-sm #{!important};

    &:disabled {
        @apply border-slate-200 bg-slate-100 #{!important};

        &:checked {
            @apply bg-slate-400 #{!important};
        }
    }
}
